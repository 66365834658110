import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Badge, Filters, DataTable, Page, Card, Link } from '@shopify/polaris';
import { formatToCurrency, getTableFooter, truncate, getDocPdfLinkForListView, getModalButton, getUserOrg, fetchTableData } from '../../shared/components/functions.jsx';
import { applyFilters, addFilters, handleFilterChange, removeFilter, handleFiltersClearAll, getUserOptions } from '../../shared/components/listFilter.jsx';
import { useUrl } from '../../shared/util/hanldesave';
import { useAuthStore, useLookupStore } from '../../context/useStore.js';
import ExcelExporterModal from '../../shared/components/modals/excelExporterModal.jsx';
import { emailSentBadge } from '../../shared/components/badges/badges.jsx';
import QuickViewModal from '../../shared/components/modals/quickViewModal.jsx';
import { useDebounce } from '../../shared/components/inputs/useDebounce.js';

export default function SalesPage() {
  const url = useUrl();
  const navigate = useNavigate();
  const { user } = useAuthStore();
  const organisations = useLookupStore((state) => state.organisations);
  const users = useLookupStore((state) => state.users);

  const invoiceStatusChoices = [
    { value: 'paid', label: 'Paid' },
    { value: 'awaiting_payment', label: 'Awaiting payment' },
    { value: 'not_fully_invoiced', label: 'Not fully invoiced' }
  ];

  const userOrg = getUserOrg(organisations, user.ORGANISATION_NAME);
  const filteredUsers = getUserOptions(users, userOrg);

  const [items, setItems] = useState([]);
  const [page, setPage] = useState(1);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState([]);
  const [filterLoading, setFilterLoading] = useState(false);
  const [filters, setFilters] = useState({
    q: { type: 'text', name: 'Q', value: url.get('q') || '' },
    dateFrom: { type: 'date', name: 'Date from', value: url.get('dateFrom') || '' },
    dateTo: { type: 'date', name: 'Date to', value: url.get('dateTo') || '' },
    customer: { type: 'text', name: 'Customer', value: url.get('customer') || '' },
    refCustomer: { type: 'text', name: 'Reference customer', value: url.get('refCustomer') || '' },
    owner: { type: 'multiple', name: 'Owner', value: url.get('owner') ? JSON.parse(url.get('owner')) : null, choices: filteredUsers },
    mail: { type: 'status', name: 'PDF emailed', value: url.get('mail') || 'all' },
    invoice_status: { type: 'multiple', name: 'Invoice status', value: JSON.parse(url.get('invoice_status')) || null, choices: invoiceStatusChoices },
    dateCustomerFrom: { type: 'date', name: 'Date customer from', value: url.get('dateCustomerFrom') || '' },
    dateCustomerTo: { type: 'date', name: 'Date customer to', value: url.get('dateCustomerTo') || '' }
  });

  const fetchData = async (abortController) => await fetchTableData(page, '/api/sales', setItems, filters, abortController, setFilterLoading);
  const debouncedFetchData = useDebounce(fetchData);

  useEffect(() => {
    const abortController = new AbortController();
    const fetchDataWithAbort = async () => debouncedFetchData(abortController);
    fetchDataWithAbort();
    return () => abortController.abort(); // Cleanup, abort controller when page/filters change
  }, [page, filters]);

  const getInvoicedStatus = (sale) => {
    if (sale.Canceled) return <Badge progress="complete">Canceled</Badge>;
    switch (sale.status_payment) {
      case 'paid':
        return (
          <Badge progress="complete" tone="success">
            Paid
          </Badge>
        );
      case 'not_fully_invoiced':
        return (
          <Badge progress="incomplete" tone="critical">
            Not fully invoiced
          </Badge>
        );
      case 'awaiting_payment':
        return (
          <Badge progress="partiallyComplete" tone="warning">
            Awaiting payment
          </Badge>
        );
      default:
        return <Badge></Badge>;
    }
  };

  const rows = Array.isArray(items)
    ? items
        .map((item) => {
          const isPmShopOrder = String(item.RefKlant).includes('PMSHOP');
          const userAbbr = users.find((u) => u.GebruikerID === item.GebruikerID)?.Afkorting;
          return [
            <Link removeUnderline url={'/sales/' + item.OrderbevestigingID}>
              {item.Orderbevestigingnummer}
              {isPmShopOrder && <span style={{ paddingLeft: '3px' }}>🛍</span>}
            </Link>,
            getModalButton(item.OrderbevestigingID, item.Orderbevestigingnummer, setModalOpen, setSelectedItem),
            getDocPdfLinkForListView(item, 'order_confirmation', item.Klantnummer, item.Orderbevestigingnummer),
            String(item.Datum).substring(0, 10),
            <Link removeUnderline url={'/companies/' + item.KlantID}>
              {truncate(item.NAAM, 30, false)}
            </Link>,
            truncate(item.RefKlant, 20, false),
            item.RefKlantDatum ? String(item.RefKlantDatum).substring(0, 10) : '',
            formatToCurrency(item.to_invoice_tax_incl),
            formatToCurrency(item.invoiced_total_tax_incl),
            formatToCurrency(item.invoiced_open_tax_incl),
            userAbbr ? <Badge>{userAbbr}</Badge> : '',
            emailSentBadge(item.Verstuurd),
            getInvoicedStatus(item)
          ];
        })
        .map((row) => {
          if (row[12].props.children === 'Canceled') return row.map((item) => <div style={{ textDecoration: 'line-through' }}>{item}</div>);
          else return row;
        })
    : [];

  const totals =
    items.length > 0
      ? [
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          formatToCurrency(items.reduce((p, c) => p + c.to_invoice_tax_incl, 0)),
          formatToCurrency(items.reduce((p, c) => p + c.invoiced_total_tax_incl, 0)),
          formatToCurrency(items.reduce((p, c) => p + c.invoiced_open_tax_incl, 0)),
          '',
          '',
          ''
        ]
      : null;

  return (
    <Page title="Sales orders" fullWidth>
      <QuickViewModal modalOpen={modalOpen} setModalOpen={setModalOpen} id={selectedItem.id} type="default" source="sales" title={`Sale #${selectedItem.number}`} />
      <Card>
        <Filters
          queryValue={filters.q.value}
          filters={addFilters(filters, setFilters, navigate, setPage)}
          appliedFilters={applyFilters(filters, setFilters, navigate, setPage)}
          loading={filterLoading}
          onQueryChange={(e) => handleFilterChange(e, filters, setFilters, 'q', navigate, setPage)}
          onQueryClear={() => removeFilter('q', filters, setFilters, navigate, setPage)}
          autoComplete="off"
          queryPlaceholder="Filter sales orders on number, reference customer, name customer, delivery term, aanbod, customer number and user"
          onClearAll={() => handleFiltersClearAll(filters, setFilters, navigate, setPage)}
        >
          <ExcelExporterModal filters={filters} setFilters={setFilters} page={page} setPage={setPage} />
        </Filters>
        <DataTable
          verticalAlign="middle"
          stickyHeader
          increasedTableDensity
          columnContentTypes={['text', 'text', 'text', 'text', 'text', 'text', 'text', 'numeric', 'numeric', 'numeric', 'numeric', 'numeric', 'numeric']}
          totals={totals}
          showTotalsInFooter
          headings={[
            <b>Number</b>,
            <b>View</b>,
            <b>PDF</b>,
            <b>Date</b>,
            <b>Customer</b>,
            <b>Reference customer</b>,
            <b>Date customer</b>,
            <b>Value (VAT incl)</b>,
            <b>Invoiced (VAT incl)</b>,
            <b>Invoiced open (VAT incl)</b>,
            <b>Owner</b>,
            <b>PDF emailed</b>,
            <b>Invoice status</b>
          ]}
          rows={rows}
          footerContent={getTableFooter(rows, page, setPage, 'sales')}
        />
      </Card>
    </Page>
  );
}
